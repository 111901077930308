import React from "react"
import Layout from "../components/layout"
import Privacy from "../components/privacy/privacy"
import SEO from "../components/seo"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy Policy Of Techvizon" pathname="/privacy-policy" description="A privacy policy for Techvizon Private Limited - a Software Development & IT Solutions Company contains data collection, storage, processing techniques and security measures to protect client’s information and their data that is stored on its site." />
    <Privacy/>
  </Layout>
)

export default PrivacyPolicyPage
